<template lang="pug">
  TabBar(
    v-model='tabslist'
    :dataId='panel.id'
    group='shared'
    :showAddButton='!isDragging'
    :activeTabId='activeTabId'
    :isActive='isActivePanel'
    @dragStart='onDragStart'
    @dragEnd='onDragEnd'
    @switchTab='switchTab'
    @focusContent='onFocusContent'
    @tabClose='onCloseTab'
    @addButtonClick='onAddButtonClick'
  )
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TabBar from '@/components/tabbar/TabBar.vue'

export default {
  name: 'PanelTabBar',

  components: { TabBar },

  props: {
    panel: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      isDragging: state => state.workbench.isDragging,
      activePanelId: state => state.workbench.activePanelId
    }),
    ...mapGetters(['itemById']),

    tabslist: {
      get () {
        const result = this.panel.tabIds
          .map(id => this.itemById('tabs', id))
        return result
      },
      set (tabs) {
        this.$store.dispatch('updatePanelTabs', {
          panel: this.panel, tabs
        })
      }
    },

    activeTabId () {
      return this.panel.activeTabId
    },

    isActivePanel () {
      return this.activePanelId === this.panel.id
    }
  },

  methods: {
    onDragStart ({ $event, tab }) {
      this.$store.commit('SET_IS_DRAGGING', true)
    },

    onDragEnd ({ $event, tab }) {
      this.$store.commit('SET_IS_DRAGGING', false)

      const targetPanelId = $event.to.dataset.id
      if (targetPanelId !== this.panel.id) {
        this.$store.commit('SET_ACTIVE_PANEL_ID', targetPanelId)
      } else {
        if (tab.id !== this.activeTabId) {
          this.switchTab(tab)
        }
      }
    },

    switchTab (tab) {
      this.$store.commit('UPDATE', {
        where: 'panels',
        data: {
          id: this.panel.id,
          activeTabId: tab.id
        }
      })
      if (!this.isActivePanel) {
        this.$store.commit('SET_ACTIVE_PANEL_ID', this.panel.id)
      }
    },

    onFocusContent () {
      this.$emit('focusContent')
    },

    async onCloseTab (tab) {
      await this.$store.dispatch('closeTab', tab.id)
    },

    onAddButtonClick () {
      this.$quickPanel.show({
        component: 'QuickPanelSymbolPicker',
        props: { panelId: this.panel.id }
      })
    }
  }

}
</script>
