<template lang="pug">
  svg(
    :viewBox='`0 0 ${width} ${height}`'
    :style='{ width, height }'
    class="text-gray-700 fill-current stroke-current"
    :stroke-width='1.5'
  )
    //- <circle cx={sx} cy={sy} r={4} />
    path(
      :d='`M${arrow[0]},${arrow[1]} Q${arrow[2]},${arrow[3]} ${arrow[4]},${arrow[5]}`'
      fill="none"
    )
    polygon(
      points="0,-4 8,0, 0,4"
      :transform='`translate(${arrow[4]},${arrow[5]}) rotate(${endAngleAsDegrees})`'
    )

</template>

<script>
import { getArrow } from 'perfect-arrows'

export default {
  name: 'Arrow',

  props: {
    p1: {
      type: Object,
      require: true
    },
    p2: {
      type: Object,
      require: true
    },
    width: {
      type: Number,
      require: true
    },
    height: {
      type: Number,
      require: true
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    arrow () {
      return getArrow(this.p1.x, this.p1.y, this.p2.x, this.p2.y, this.options)
    },

    endAngleAsDegrees () {
      return this.arrow[6] * (180 / Math.PI)
    }
  }
}
</script>

<style>

</style>
