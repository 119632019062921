<template lang="pug">
  div.relative.flex-auto.py-4.bg-gray-400.overflow-y-auto.scrollbar-thin.scrollbar-thumb-gray-600.scrollbar-track-transparent
    component(:is='componentName')
</template>

<script>
import Explorer from './content/Explorer.vue'
import Settings from './content/Settings.vue'
// import Projects from './content/Projects.vue'

export default {
  components: {
    Explorer,
    Settings
    // Projects
  },

  props: {
    componentName: {
      type: String,
      required: false
    }
  }
}
</script>
