<template lang="pug">
  .flex-shrink-0.relative.flex.justify-start.items-stretch.content-start.overflow-hidden
    input(
      type='file'
      ref='uploadInput'
      style='display: none;'
      accept='.json'
      @input='importSymbols'
    )

    nav.flex-shrink-0.flex.flex-col.justify-between.items-center.content-center.py-6(class='px-2.5')
      div(
        v-for='(group, key, index) in tabs'
        :key='key'
      )
        div(
          v-for='tab, index in group'
          :id='tab.id'
          :class='{ "mb-1.5" : index !== (group.length - 1)}'
        )
          Button(
            :show-label='false'
            :icon='tab.icon'
            :label='tab.label'
            :active='isExpanded && activeContentId === tab.id'
            theme='workbench'
            size='large'
            v-tippy='tab.tippy ? { ...tippyOpts, ...tab.tippy } : { ...tippyOpts, content: tab.label }'
            :disabled='tab.disabled && tab.disabled === true'
            @click='tab.onClick'
          )
          ContextMenu(
            v-if='contextMenu === tab.id'
            :target='"#" + tab.id'
            :items='tab.contextMenu'
            placement='right-end'
            @destroyed='contextMenu = null'
          )

</template>

<script>
import padStart from 'lodash/padStart'
import { downloadFromString } from '@/util'
import { mapGetters } from 'vuex'
import ModalDownload from '@/components/modal/ModalDownload.vue'
import ModalProject from '@/components/modal/ModalProject.vue'

export default {
  name: 'Sidebar',

  props: {
    isExpanded: {
      type: Boolean,
      required: true
    },

    activeContentId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      contextMenu: null,
      tippyOpts: {
        placement: 'right'
        // delay: [750, 200]
      }
    }
  },

  computed: {
    ...mapGetters(['allItems', 'itemById', 'projectIsLive']),

    tabs () {
      const projectIsLive = this.projectIsLive
      const hasUnsavedChanges = this.$store.state.workbench.hasUnsavedChanges === true

      return {
        top: [
          {
            id: 'sidebar-tab-explorer',
            label: 'All Symbols',
            icon: 'PoundIcon',
            onClick: () => {
              this.$emit('toggle', {
                id: 'sidebar-tab-explorer',
                componentName: 'Explorer'
              })
            }
          },
          {
            id: 'sidebar-tab-save',
            label: hasUnsavedChanges ? 'Save changes' : 'All saved',
            icon: hasUnsavedChanges ? 'ContentSaveAlertOutlineIcon' : 'ContentSaveOutlineIcon',
            disabled: !hasUnsavedChanges,
            onClick: async () => {
              if (!hasUnsavedChanges) return
              try {
                await this.$store.dispatch('saveProject')

                // show toast
                this.$toast.open({
                  type: 'success',
                  message: `Project saved (${projectIsLive ? 'online' : 'locally'})`
                })
              } catch (error) {
                this.$toast.open({
                  type: 'error',
                  message: error.message || 'An error occurred. Please try again later.'
                })
                console.error('Error updating project', error)
              }
            }
          },
          // {
          //   id: 'sidebar-tab-projects',
          //   label: 'Projects',
          //   icon: 'FolderOpenOutlineIcon',
          //   onClick: () => {
          //     this.$emit('toggle', {
          //       id: 'sidebar-tab-projects',
          //       componentName: 'Projects'
          //     })
          //   }
          // },
          {
            id: 'sidebar-tab-download',
            label: 'Download Result',
            icon: 'FileDownloadOutlineIcon',
            onClick: () => {
              this.$modal.show({ component: ModalDownload })
            }
          },
          {
            id: 'sidebar-tab-settings',
            label: 'Settings',
            icon: 'CogOutlineIcon',
            onClick: () => {
              this.$emit('toggle', {
                id: 'sidebar-tab-settings',
                componentName: 'Settings'
              })
            }
          }
        ],
        bottom: [
          {
            id: 'sidebar-tab-link',
            label: projectIsLive ? 'Get the link' : 'Create a link',
            icon: 'LinkVariantIcon',
            onClick: async () => {
              this.$modal.show({ component: ModalProject })
            }
          },
          {
            id: 'sidebar-tab-import-export',
            label: 'Import / Export',
            tippy: {
              ...this.tippyOpts,
              hideOnClick: true,
              content: 'Import / Export',
              onShow: (instance) => {
                if (this.contextMenu === 'sidebar-tab-import-export') return false
              }
            },
            icon: 'SwapVerticalIcon',
            contextMenu: [
              {
                label: 'Export Project',
                icon: 'ArrowUpIcon',
                onClick: () => {
                  this.contextMenu = null
                  this.exportSymbols()
                }
              },
              {
                label: 'Import Project',
                icon: 'ArrowDownIcon',
                onClick: () => {
                  this.contextMenu = null
                  this.$refs.uploadInput.click()
                }
              }
            ],
            onClick: (e) => {
              this.contextMenu = this.contextMenu === 'sidebar-tab-import-export'
                ? null
                : 'sidebar-tab-import-export'
            }
          },
          {
            id: 'sidebar-tab-guide',
            label: 'Tutorial',
            icon: 'SchoolOutlineIcon',
            onClick: () => {
              const routeData = this.$router.resolve({
                name: 'tutorial'
              })

              window.open(routeData.href, '_blank')
            }
          }
        ]
      }
    }

  },

  methods: {

    exportSymbols () {
      const project = this.$store.state.project
      const symbols = this.$store.getters.allItems('symbols')
      const json = JSON.stringify({ ...project, symbols })

      // Convert JSON string to BLOB.
      const pad = (str) => padStart(str, 2, '0')
      const now = new Date()
      const dateStr = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`
      downloadFromString(json, `${dateStr}.json`)
    },

    async importSymbols () {
      const file = this.$refs.uploadInput.files[0]
      const { project, symbols } = await this.parseProjectFile(file)

      // assign current project id to imported project
      project.id = this.$store.state.project.id

      await this.$store.dispatch('createWorkspace', { project, symbols })
      this.$store.commit('SET_HAS_UNSAVED_CHANGES', true)
      this.$generate()

      // reset file upload value (so it works on second run with same file)
      this.$refs.uploadInput.value = ''
    },

    readFileAsync (file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onload = async (event) => {
          if (event.target.readyState === 2) {
            // import to store
            const symbols = JSON.parse(reader.result)
            return resolve(symbols)
          }
        }
        reader.readAsText(file)
      })
    },

    async parseProjectFile (file) {
      if (file.type !== 'application/json') {
        alert('The selected file is not a JSON file 😲')
        return
      }

      // read file & parse JSON
      const content = await this.readFileAsync(file)

      // legacy: in old versions,
      // content was an array of symbols
      let project, symbols
      if (Array.isArray(content)) {
        project = null
        symbols = content
      } else if (typeof content.symbols !== 'undefined') {
        symbols = content.symbols
        delete content.symbols
        project = content
      } else {
        alert('Could not parse file 😡')
        return
      }

      return { project, symbols }
    }
  }

}
</script>
