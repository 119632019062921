<template lang="pug">
  div(class='pl-2.5 pr-5')
    .my-3.whitespace-nowrap.font-medium Symbols

    TextInput.mb-3(
      v-show='symbols.length > 1'
      type='search'
      ref='searchInput'
      v-model='searchTerm'
      maxlength='40'
      placeholder='Type something to search'
      size='small'
      :transparent='true'
      :hint='searchFieldHint'
    )

    transition-group.min-w-fit(
      tag="ul"
      name="list-transition"
    )
      li(
        v-for='(symbol, index) in filteredSymbols'
        :key='symbol.id'
      )

        .explorer-symbol.cursor-pointer.flex.justify-between.items-center.content-center.whitespace-nowrap.border-b.border-dotted.border-gray-500.text-sm.leading-normal(
          class='pl-1.5'
          :style='`--color:${symbol.color};--contrast:${symbol.contrast};`'
          @click='openSymbol(symbol)'
        )
          | {{ symbol.name }}

          a(
            class='text-gray-600 hover:text-black'
            v-if='symbol.canDelete'
            v-tippy='{ content: "Delete", placement: "right" }'
            @click.stop='removeSymbol(symbol)'
          )
            //- symbol height is 20px
            TrashCanOutlineIcon(:size='20')

        ul(
          class='mt-0.5 ml-2.5 text-sm'
          v-if='symbol.matches && symbol.matches.length'
        )
          li(
            v-for='match in symbol.matches'
            :key='match.refIndex'
            @click='openSymbol(symbol, match.refIndex)'
            v-html='highlightText(match.value, match.indices)'
            class='cursor-pointer border-b border-dotted border-gray-500 hover:underline'
          )

    //- remove all symbols button
    Button.mt-4(
      v-show='!searchTerm.length && symbols.length > 1'
      label='Remove all Symbols'
      size='small'
      @click='removeAllSymbolsButtonClick'
    )

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import emitter from '@/plugins/emitter'
import Fuse from 'fuse.js'

export default {
  name: 'Explorer',

  data () {
    return {
      searchTerm: ''
    }
  },

  mounted () {
    // focus filter input
    setTimeout(() => {
      this.$refs.searchInput.focus()
    }, 0)
  },

  computed: {
    ...mapState({
      activePanelId: state => state.workbench.activePanelId
    }),
    ...mapGetters([
      'allItems', 'itemById'
    ]),

    symbols () {
      const startSymbolId = this.$store.state.project.startSymbolId

      return this.allItems('symbols')
        .map(s => ({
          id: s.id,
          name: s.name,
          color: s.color,
          contrast: this.$contrastColorString(s.color),
          canDelete: s.id !== startSymbolId,
          content: s.content
        }))
        .sort((a, b) => b.id === startSymbolId ? 1 : a.name.localeCompare(b.name)) // TODO: set "lang" from store: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
    },

    filteredSymbols () {
      if (!this.searchTerm.length) {
        return this.symbols
      }

      const fuseOptions = {
        minMatchCharLength: 2,
        includeMatches: true,
        ignoreLocation: true,
        useExtendedSearch: true,
        // ignoreFieldNorm: true,
        keys: [
          {
            name: 'name',
            weight: 1
          },
          {
            name: 'content.content.text', // rule
            weight: 0.2
          },
          {
            name: 'content.content.content.text', // rulegroup
            weight: 0.2
          }
        ]
      }
      const fuse = new Fuse(this.symbols, fuseOptions)
      const result = fuse
        .search(`'"${this.searchTerm}"`)
        .map(i => ({
          ...i.item,
          matches: i.matches.filter(
            m => m.value !== i.item.name
          )
        }))

      // console.warn(result)
      return result
    },

    searchFieldHint () {
      if (!this.searchTerm.length) {
        return null
      }
      if (!this.filteredSymbols.length) {
        return `No results for "${this.searchTerm}"`
      }
      return `Search results for "${this.searchTerm}":`
    }
  },

  methods: {

    getActiveEditorId () {
      const tab = this.itemById('panels', this.activePanelId)
      if (!tab) return

      return this.itemById('tabs', tab.activeTabId).editorId
    },

    async openSymbol (symbol, refIndex) {
      await this.$store.dispatch('openSymbolInTab', {
        symbolId: symbol.id,
        targetPanelId: this.activePanelId
      })

      // fire event to focus text node at index
      if (typeof refIndex !== 'undefined') {
        setTimeout(() => {
          emitter.emit('editorEvent-' + this.getActiveEditorId(), {
            name: 'focusTextnodeByIndex',
            values: { index: refIndex }
          })
        }, 0)
      }
    },

    removeSymbol (symbol) {
      this.$modal.show({
        props: {
          title: 'Confirm removal',
          content: `<p class="mb-3">Do you really want to delete <strong>#${symbol.name}</strong>?</p><p>Before deleting it, make sure that all references to this symbol have been removed.</p>`,
          maxWidth: '450px',
          buttons: [
            {
              label: 'Abort',
              onClick: this.$modal.close
            },
            {
              label: 'Confirm',
              type: 'primary',
              onClick: () => {
                this.$modal.close()
                this.$store.dispatch('removeSymbol', symbol.id)
                this.$store.commit('SET_HAS_UNSAVED_CHANGES', true)
              }
            }
          ]
        }
      })
    },

    highlightText (inputText, regions) {
      // src: https://gist.github.com/evenfrost/1ba123656ded32fb7a0cd4651efd4db0
      // discussion: https://github.com/krisk/Fuse/issues/6
      let content = ''
      let nextUnhighlightedRegionStartingIndex = 0

      for (let i = 0, l = regions.length; i < l; i++) {
        const region = regions[i]
        const lastRegionNextIndex = region[1] + 1

        content += [
          inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
          '<strong>',
          inputText.substring(region[0], lastRegionNextIndex),
          '</strong>'
        ].join('')

        nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
      }

      content += inputText.substring(nextUnhighlightedRegionStartingIndex)

      return content
    },

    removeAllSymbolsButtonClick () {
      this.$modal.show({
        props: {
          title: 'Confirm removal',
          content: `<p>Do you really want to delete <strong>${this.symbols.length > 2 ? 'all ' : ''}${this.symbols.length} Symbols</strong>? This action cannot be undone.</p>`,
          maxWidth: '450px',
          buttons: [
            {
              label: 'Abort',
              onClick: this.$modal.close
            },
            {
              label: 'Confirm',
              type: 'primary',
              onClick: () => {
                this.$modal.close()
                this.$store.dispatch('createWorkspace', { exampleStory: false })
                this.$store.commit('SET_HAS_UNSAVED_CHANGES', true)
                this.$generate()
              }
            }
          ]
        }
      })
    }

  }
}
</script>

<style lang="scss">
.explorer-symbol {
  border-left: 4px solid var(--color);
  &:hover {
    color: var(--contrast);
    background-color: var(--color);
  }
}
</style>
