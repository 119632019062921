<template lang="pug">
  .flex.h-screen.w-screen.bg-gray-300
    ModalContainer
    QuickPanelContainer

    SidebarMenu(
      :is-expanded='sidebar.isExpanded'
      :active-content-id='sidebar.content.id'
      @toggle='toggleSidebarContent'
    )

    .min-w-0.min-h-0.flex-grow
      Splitpanes
        Pane(
          v-if='sidebar.isExpanded'
          size='20'
        )
          SidebarContent(:component-name='sidebar.content.componentName')
        Pane
          Splitpanes.py-4.pr-4(
            :horizontal='true'
          )
            Pane(
              v-for='group in panelGroups'
              :key='group.id'
            )
              Splitpanes
                Pane(
                  v-for='panel in group.panels'
                  :key='panel.id'
                )
                  Panel(
                    :panel='panel'
                    :panel-is-active='activePanelId === panel.id'
                    ref='panel'
                  )
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SidebarMenu from '@/components/sidebar/SidebarMenu.vue'
import SidebarContent from '@/components/sidebar/SidebarContent.vue'
import Panel from '@/components/panel/Panel.vue'
import store from '@/store'

const loadWorkspace = async (to, next, clear = true) => {
  // load project from backend or browser storage
  try {
    const { project, symbols } = await store.dispatch('loadProject', to.params.projectId)
    await store.dispatch('createWorkspace', { project, symbols, clear: clear })
  } catch (error) {
    alert(`The project could not be loaded (${error}).`)
    if (to.path !== '/') {
      return next('/')
    }
  }
  next()
}

export default {
  name: 'Workbench',

  metaInfo () {
    return {
      // title will be injected into parent titleTemplate
      title: this.$store.state.project.title
    }
  },

  components: { SidebarMenu, SidebarContent, Panel },

  data () {
    return {
      sidebar: {
        isExpanded: false,
        content: {
          id: 'sidebar-tab-explorer',
          componentName: 'Explorer'
        }
      }
    }
  },

  async beforeRouteEnter (to, from, next) {
    await loadWorkspace(to, next, false)
  },

  async beforeRouteUpdate (to, from, next) {
    if (!to.params.noReload) {
      // clear workspace first
      await loadWorkspace(to, next)
    } else {
      next()
    }
  },

  mounted () {
    // create backup on page leave
    window.addEventListener('beforeunload', this.handleBeforeUndload, { capture: true })

    // global key commands
    window.addEventListener('keydown', this.handleGlobalHotkeys)

    // wait until all child components are rendered
    this.$nextTick(() => {
      // generate on first run
      this.$generate()
    })

    // TODO: init idle timer to backup to localstorage
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handleBeforeUndload)
    window.removeEventListener('keydown', this.handleGlobalHotkeys)
  },

  computed: {
    ...mapState({
      panelGroupsOrder: state => state.workbench.panelGroupsOrder,
      activePanelId: state => state.workbench.activePanelId
    }),
    ...mapGetters(['allItems', 'itemById']),

    panelGroups () {
      return this.panelGroupsOrder
        .map(id => this.itemById('panelGroups', id))
        .map(group => {
          const groupCopy = { ...group }
          groupCopy.panels = groupCopy.panelIds
            .map(id => this.itemById('panels', id))
            // .filter(Boolean)
          return groupCopy
        })
    }
  },

  methods: {
    async handleBeforeUndload (event) {
      // Show message if changes are not saved
      if (this.$store.state.workbench.hasUnsavedChanges === true) {
        event.preventDefault()
        event.returnValue = 'Are you sure you want to leave? If you leave now, all unsaved data will be lost.'
      }
    },

    toggleSidebarContent (sidebarContent) {
      const sidebar = this.sidebar

      if (sidebarContent) {
        if (sidebar.content.id === sidebarContent.id) {
          sidebar.isExpanded = !sidebar.isExpanded
        } else {
          sidebar.content = sidebarContent
          if (!sidebar.isExpanded) sidebar.isExpanded = true
        }
      }
    },

    handleGlobalHotkeys (e) {
      // handle select all key command
      if (e.ctrlKey && e.key.toLowerCase() === 'a') this.handleSelectAllKeystroke(e)
      // handle quickPanel symbol picker
      if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'p') this.openSymbolPicker(e)
    },

    handleSelectAllKeystroke (e) {
      if (
        !e.target.isContentEditable &&
        e.target instanceof HTMLInputElement === false &&
        e.target instanceof HTMLTextAreaElement === false
      ) {
        e.preventDefault()
      }
    },

    openSymbolPicker (e) {
      e.preventDefault()
      this.$quickPanel.show({
        component: 'QuickPanelSymbolPicker'
      })
    }
  }

}
</script>
