<template lang="pug">
  div(class='pl-2.5 pr-5')
    .my-3.whitespace-nowrap.font-medium Settings
    .space-y-3.min-w-min
      TextInput(
        :value='project.title'
        type='text'
        size='small'
        :transparent='true'
        label='Project name'
        maxlength='60'
        :required='true'
        @input='updateProjectSetting("title", $event)'
      )
      SelectInput(
        :value='project.startSymbolId'
        size='small'
        :transparent='true'
        label='Start symbol'
        hint='Controls at which symbol Plauder starts to generate the text.'
        :items='symbols'
        :required='true'
        @input='updateProjectSetting("startSymbolId", $event)'
      )
      SelectInput(
        :value='project.separator'
        size='small'
        :transparent='true'
        label='Separator'
        hint='Controls how the rules in the start symbol are separated.'
        :items='separators'
        :required='true'
        @input='updateProjectSetting("separator", $event)'
      )

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SelectInput from '@/components/form/SelectInput'
import Checkbox from '@/components/form/Checkbox'

export default {
  name: 'Settings',

  components: {
    SelectInput, Checkbox
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapState({
      project: state => state.project
    }),
    ...mapGetters([
      'allItems', 'itemById'
    ]),

    symbols () {
      return this.allItems('symbols')
        .map(s => ({
          value: s.id,
          label: s.name,
          selected: s.id === this.project.startSymbolId
          // color: s.color,
          // contrast: this.$contrastColorString(s.color),
          // canDelete: s.id !== startSymbolId,
          // content: s.content
        }))
    },

    separators () {
      return [
        {
          value: '\n',
          label: 'Line break',
          selected: this.project.separator === '\n'
        },
        {
          value: ' ',
          label: 'Space',
          selected: this.project.separator === ' '
        }
      ]
    }

  },

  methods: {
    updateProjectSetting (key, value) {
      this.$store.commit('UPDATE_PROJECT', {
        [key]: value
      })

      if (['startSymbolId', 'separator'].includes(key)) {
        this.$generate()
      }

      this.$store.commit('SET_HAS_UNSAVED_CHANGES', true)
    }
  }
}
</script>
