<template lang="pug">
  .relative.h-full.bg-white(
  )
    Editor(
      ref='panelEditor'
      :currentSymbol='symbolData'
      :editorIsActive='editorIsActive'
      :editorData='editorData'
      :allSymbols='allItems("symbols")'
      :savedRules='$savedRules'
      :startSymbolId='startSymbolId'
      :keymapConfig='keymapConfig'
      :handleOpenSymbol='handleOpenSymbol'
      @focus='onFocus'
      @blur='onBlur'
      @update='updateAndGenerate'
      @createSymbol='handleCreateSymbol'
    )
    PanelMenu(
      :items='panelMenuItems'
    )
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Editor from '@/components/editor/Editor.vue'

export default {
  name: 'PanelEditor',

  components: {
    Editor
  },

  props: {

    editorIsActive: {
      type: Boolean,
      required: true
    },

    editorData: {
      type: Object,
      required: true
    },

    symbolData: {
      type: Object,
      required: true
    }

  },

  mounted () {
    setTimeout(() => {
      this.restoreScrollPosition()
      this.restoreSelection()
      if (this.editorIsActive) {
        this.focusEditor()
      }
    }, 0)
  },

  beforeDestroy () {
    this.saveSelection()
    this.saveScrollPosition()
  },

  data () {
    return {
      keymapConfig: {
        escapeKeyMethod: this.handleCloseSymbol
      }
    }
  },

  computed: {
    ...mapState({
      startSymbolId: state => state.project.startSymbolId,
      panelGroupsOrder: state => state.workbench.panelGroupsOrder,
      isDragging: state => state.workbench.isDragging,
      activePanelId: state => state.workbench.activePanelId
    }),
    ...mapGetters([
      'resolvePanelGroup',
      'allItems', 'itemById', 'findItemsByKeyValueContains'
    ]),

    panelMenuItems () {
      return [
        {
          id: 'toggle-word-wrap',
          label: 'Toggle Word Wrap',
          showLabel: false,
          icon: this.editorData.wordWrap ? 'WrapIcon' : 'WrapDisabledIcon',
          tippy: { placement: 'left', content: 'Toggle Word Wrap' },
          onClick: () => {
            this.$store.commit('UPDATE', {
              where: 'editors',
              data: {
                id: this.editorData.id,
                wordWrap: !this.editorData.wordWrap
              }
            })
          }
        }
      ]
    }
  },

  watch: {
    isDragging (newVal, oldVal) {
      if (newVal === true) {
        this.$refs.panelEditor.editor.setOptions({ editable: false })
        this.$refs.panelEditor.updateSymbolMenuPosition()
      } else {
        this.$refs.panelEditor.editor.setOptions({ editable: true })
      }
    },

    editorIsActive (isActive) {
      if (isActive) {
        // wait so click inside editor is handled
        this.$nextTick(() => {
          if (!this.$refs.panelEditor.editor.focused) {
            this.focusEditor()
          }
        })
      }
    }

  },

  methods: {

    // set active panel
    onFocus (view, event) {
      const currPanelId = this.$parent.panel.id
      if (this.activePanelId !== currPanelId) {
        this.$store.commit('SET_ACTIVE_PANEL_ID', currPanelId)
      }
    },

    // on blur save selection and scroll positon to store
    onBlur (view, event) {
      this.saveSelection()
      this.saveScrollPosition()
    },

    focusEditor () {
      this.$refs.panelEditor.focus()
    },

    saveSelection () {
      // save only if data changed
      const editorComponent = this.$refs.panelEditor.editor
      if (!editorComponent) return // hot reload crash fix

      const { id, selection: oldSel } = this.editorData
      const { node, from, to } = editorComponent.state.selection
      const isNodeSel = typeof node !== 'undefined'
      if (isNodeSel !== oldSel.isNodeSel || from !== oldSel.from || to !== oldSel.to) {
        this.$store.commit('UPDATE', {
          where: 'editors',
          data: {
            id,
            selection: { isNodeSel, from, to }
          }
        })
      }
    },

    restoreSelection () {
      const { isNodeSel, from } = this.editorData.selection

      if (from) {
        if (isNodeSel) {
          this.$refs.panelEditor.editor.commands.setNodeSelection(from)
        } else {
          this.$refs.panelEditor.editor.commands.setTextSelection(from)
        }
      }
    },

    saveScrollPosition () {
      // save only if data changed
      const scroller = this.$refs.panelEditor.$refs.scroller
      if (!scroller) return
      const { scrollTop, scrollLeft } = scroller
      const oldPos = this.editorData.scrollPos
      if (
        scrollTop !== oldPos.top ||
        scrollLeft !== oldPos.left
      ) {
        this.$store.commit('UPDATE', {
          where: 'editors',
          data: {
            id: this.editorData.id,
            scrollPos: { top: scrollTop, left: scrollLeft }
          }
        })
      }
    },

    restoreScrollPosition () {
      // set scroll position
      const { top, left } = this.editorData.scrollPos
      this.$refs.panelEditor.$refs.scroller.setScrollPosition({
        scrollTop: top,
        scrollLeft: left
      })
    },

    async updateAndGenerate (editorJSON) {
      this.$store.commit('UPDATE', {
        where: 'symbols',
        data: {
          id: this.symbolData.id,
          content: editorJSON.content
        },
        freeze: true
      })
      this.$generate()

      // set store variable "hasUnsavedChanges" / notify user of unsaved changes
      this.$store.commit('SET_HAS_UNSAVED_CHANGES', true)
    },

    handleOpenSymbol (node) {
      const symbolId = node.attrs.id
      // check if symbol exists
      if (!this.itemById('symbols', symbolId)) {
        alert('Oh no! This symbol does not exist 😲 You must have deleted it!')
        return
      }

      // open symbol tab
      this.$store.dispatch('openSymbolInTab', { symbolId })
    },

    handleCloseSymbol () {
      this.$emit('close')
    },

    handleCreateSymbol (symbol) {
      this.$store.commit('INSERT', {
        where: 'symbols',
        data: symbol
      })
    }

  }
}
</script>
