<template lang="pug">
  Modal(
    :close-button='false'
    min-width='520px'
    max-width='520px'
    @close='$modal.close()'
  )
    template(v-if='projectIsLive')
      //- live project

      template(slot='header') Link created successfully!
      .space-y-4(slot='body')
        p You can access and edit your project at the following link:
        p.p-2.border.border-gray-100
          a.project-link(
            :href='currentProjectUrl'
            v-tippy="tippyCopyInstance"
            @click.prevent='copyLink'
          ) {{ currentProjectUrl }}
        .flex
          .flex.items-center.mr-3
            .flex.items-center.justify-center.rounded-full.bg-blue-50.w-10.h-10.text-blue-600 <ContentSaveOutlineIcon />
          div From now on, your project will be saved online when you click the save icon.
        .flex.mt-2
          .flex.items-center.mr-3
            .flex.items-center.justify-center.rounded-full.bg-blue-50.w-10.h-10.text-blue-600 <BookmarkPlusOutlineIcon />
          div Tip: Bookmark this link so that you can find your project again later.

    template(v-else)
      //- local project

      template(slot='header') Create a link
      .space-y-4(slot='body')
        .flex
          .flex.mr-3
            .flex.items-center.justify-center.rounded-full.bg-blue-50.w-10.h-10.text-blue-600 <ContentSaveOutlineIcon />
          .space-y-4
            p Your project is saved locally in your browser's memory on your computer when you click the save icon.
            p If instead you want your project to be saved online and accessible via a link from anywhere, click "<strong>Create a link</strong>".

    template(
      slot='footer'
    )
      template(v-if='projectIsLive')
        //- live project
        Button(
          class='mr-2.5'
          label='Close'
          @click='$modal.close()'
        )

      template(v-else)
        Button(
          class='mr-2.5'
          label='Cancel'
          @click='$modal.close()'
        )
        //- local project
        Button(
          theme='primary'
          label='Create a link'
          @click='saveProjectOnline'
        )

</template>

<script>
import { copyToClipboard } from '@/util'

export default {
  name: 'ModalProject',

  data () {
    return {
      showSaveHelp: false,
      tippyCopyInstance: {
        placement: 'right',
        content: 'Click to copy'
      }
    }
  },

  computed: {
    project () {
      return this.$store.state.project
    },

    projectIsLive () {
      return this.$store.getters.projectIsLive
    },

    currentProjectUrl () {
      return this.projectIsLive
        ? process.env.VUE_APP_PUBLIC_URL + '/' + this.project.id
        : null
    }

  },

  methods: {

    async saveProjectOnline () {
      try {
        const projectId = await this.$store.dispatch('createProject')

        // show toast
        this.$toast.open({
          type: 'success',
          message: 'Project uploaded'
        })

        // replace current route and add replace
        this.$router.replace({
          name: 'workbench', params: { projectId, noReload: true }
        })
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: error.message || 'An error occurred. Please try again later.'
        })
        console.error('Error uploading project', error)
      }
    },

    async saveProjectLocally () {
      await this.$store.dispatch('saveProject')
    },

    copyLink (e) {
      copyToClipboard(this.currentProjectUrl)

      // show Feedback
      const btn = e.target
      btn._tippy.hide()
      setTimeout(() => {
        btn._tippy.destroy()
        this.$tippy(btn, {
          showOnCreate: true,
          placement: this.tippyCopyInstance.placement,
          content: 'Copied'
        })
        setTimeout(() => {
          if (btn._tippy) {
            btn._tippy.setContent(this.tippyCopyInstance.content)
          }
        }, 5000)
      }, 200)
    }
  }
}
</script>

<style lang="scss">
a.project-link {
  @apply font-bold text-black border-b-2 border-transparent hover:border-black;

  // be carful about nested elements inside button
  // src: https://css-tricks.com/slightly-careful-sub-elements-clickable-things/
  & > * {
    @apply pointer-events-none;
  }
}

</style>
