<template lang="pug">
  .flex.justify-center.content-center.items-center(
    class='pl-1.5'
    ref='container'
  )
    button(
      :class='[ \
        "cursor-pointer w-8 h-8 flex justify-center content-center items-center text-gray-800 rounded-md hover:bg-gray-400 hover:bg-opacity-90 hover:text-blue-700 focus:outline-none", \
        { "bg-gray-400 bg-opacity-90": menuIsVisible } \
      ]'
      ref='trigger'
      @click='menuIsVisible = !menuIsVisible'
    )
      DotsHorizontalIcon(:size='22')

    ContextMenu(
      v-if='menuIsVisible'
      :target='$refs.trigger'
      :items='items'
      placement='bottom-end'
      @destroyed='menuIsVisible = false'
    )

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TabBarMenu',

  props: {
    panel: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      menuIsVisible: false,
      popup: null
    }
  },

  computed: {
    ...mapGetters(['allItems', 'itemById', 'resolvePanelGroup']),

    canSplitVertically () {
      // disallow splitting if current panelGroup has a panel with no tabIds
      return !this.resolvePanelGroup(this.panel.id).panelIds
        .some(id => this.itemById('panels', id).tabIds.length === 0)
    },

    canSplitHorizontally () {
      // disallow splitting if a panelGroup with one panel and no tabIds exists
      return this.allItems('panelGroups')
        .some(
          g => g.panelIds.length === 1 && this.itemById('panels', g.panelIds[0]).tabIds.length === 0
        ) === false
    },

    items () {
      return [
        {
          label: 'Add Symbol',
          icon: 'PoundIcon',
          onClick: () => {
            this.menuIsVisible = false
            this.$quickPanel.show({
              component: 'QuickPanelSymbolPicker',
              props: { panelId: this.panel.id }
            })
          }
        },
        {
          label: 'Split Right',
          disabled: !this.canSplitVertically,
          icon: 'ViewSplitVerticalIcon',
          onClick: () => {
            this.menuIsVisible = false
            if (!this.canSplitVertically) return
            this.$store.dispatch('addPanel', {
              panelIdFrom: this.panel.id
            })
          }
        },
        {
          label: 'Split Bottom',
          disabled: !this.canSplitHorizontally,
          icon: 'ViewSplitHorizontalIcon',
          onClick: () => {
            this.menuIsVisible = false
            if (!this.canSplitHorizontally) return
            this.$store.dispatch('addPanelGroup', {
              panelIdFrom: this.panel.id
            })
          }
        }
      ]
    }

  },

  methods: {}
}
</script>
