<script>
import classNames from 'classnames'
export default {
  name: 'SelectInput',

  props: {

    id: {
      type: String,
      required: false
    },

    label: {
      type: String,
      required: true
    },

    value: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: 'normal'
    },

    theme: {
      type: String,
      default: 'regular'
    },

    required: {
      type: Boolean,
      required: false
    },

    hint: {
      type: String,
      required: false
    },

    requirements: {
      type: String,
      required: false
    }
  },

  render: function (h, context) {
    const labelElement = () => {
      return h(
        'label',
        { class: 'font-medium text-sm' },
        this.label
      )
    }

    const inputDomAttrs = () => {
      const result = {
        type: 'checkbox',
        checked: this.value === true
      }
      if (typeof this.id !== 'undefined') result.id = this.id
      if (typeof this.required !== 'undefined') result.required = this.required

      return result
    }

    const inputElement = () => {
      return h(
        'input',
        {
          ref: 'input',
          class: this.inputClasses,
          attrs: inputDomAttrs(),
          on: {
            input: this.onInput
          }
        }
      )
    }

    const hint = () => {
      return h(
        'div',
        { class: 'text-xs mt-1' },
        this.hint
      )
    }

    const requirements = () => {
      return h(
        'div',
        {
          class: classNames(
            'text-xs mt-1.5 text-red-500',
            (this.touched && !this.isValid ? 'visible' : 'invisible')
          )
        },
        this.requirements
      )
    }

    const children = []

    const inputWrapper = h(
      'div',
      {
        class: 'flex items-center space-x-2'
      },
      [
        labelElement(),
        inputElement()
      ]
    )

    children.push(inputWrapper)

    if (this.hint && this.isValid) {
      children.push(hint())
    }

    children.push(requirements())

    return h(
      'div',
      {
        class: 'flex flex-col'
      },
      children
    )
  },

  data () {
    return {
      isValid: true,
      touched: false
    }
  },

  computed: {

    inputClasses () {
      const theme = {
        regular: classNames(
          this.touched && !this.isValid
            ? 'border-red-500 focus:ring focus:ring-red-500 focus:ring-opacity-25'
            : 'border-gray-700 focus:border-blue-600 focus:ring focus:ring-blue-600 focus:ring-opacity-25'
        )
      }
      const size = {
        normal: 'h-6 w-6',
        small: 'h-4 w-4'
      }

      return classNames([
        'transition focus:outline-none',
        theme[this.theme],
        size[this.size]
      ])
    }

  },

  methods: {

    onInput () {
      if (!this.touched) this.touched = true
      const el = this.$refs.input
      this.isValid = el.checkValidity()
      this.$emit('input', el.checked)
    },

    focus () {
      this.$refs.input.focus()
    }

  }

}
</script>
