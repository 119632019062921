<script>
import classNames from 'classnames'
export default {
  name: 'SelectInput',

  props: {

    id: {
      type: String,
      required: false
    },

    label: {
      type: String,
      required: false
    },

    value: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      required: true
    },

    size: {
      type: String,
      default: 'normal'
    },

    theme: {
      type: String,
      default: 'regular'
    },

    transparent: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      required: false
    },

    hint: {
      type: String,
      required: false
    },

    requirements: {
      type: String,
      required: false
    }
  },

  render: function (h, context) {
    const labelElement = () => {
      return h(
        'label',
        { class: 'font-medium text-sm' },
        this.label
      )
    }

    const inputDomAttrs = () => {
      const result = {}
      if (typeof this.id !== 'undefined') result.id = this.id
      if (typeof this.required !== 'undefined') result.required = this.required

      return result
    }

    const optionItems = () => {
      const result = []
      for (let i = 0, l = this.items.length; i < l; i++) {
        const item = this.items[i]
        result.push(
          h(
            'option',
            {
              domProps: {
                value: item.value,
                selected: item.selected || false
              }
            },
            item.label
          )
        )
      }
      return result
    }

    const inputElement = () => {
      return h(
        'select',
        {
          ref: 'input',
          class: this.inputClasses,
          attrs: inputDomAttrs(),
          domProps: {
            value: this.value
          },
          on: {
            input: this.onInput
          }
        },
        optionItems()
      )
    }

    const hint = () => {
      return h(
        'div',
        { class: 'text-xs mt-1.5' },
        this.hint
      )
    }

    const requirements = () => {
      return h(
        'div',
        {
          class: classNames(
            'text-xs mt-1.5 text-red-500',
            (this.touched && !this.isValid ? 'visible' : 'invisible')
          )
        },
        this.requirements
      )
    }

    const children = []

    if (typeof this.label === 'string') {
      children.push(labelElement())
    }

    children.push(inputElement())

    if (this.hint && this.isValid) {
      children.push(hint())
    }

    children.push(requirements())

    return h(
      'div',
      children
    )
  },

  data () {
    return {
      isValid: true,
      touched: false
    }
  },

  computed: {

    inputClasses () {
      const theme = {
        regular: classNames(
          'text-black',
          this.transparent
            ? 'bg-white bg-opacity-25 focus:bg-white placeholder-gray-900'
            : 'bg-white placeholder-gray-400',
          this.touched && !this.isValid
            ? 'border-red-500 focus:ring focus:ring-red-500 focus:ring-opacity-25'
            : 'border-gray-700 focus:border-blue-600 focus:ring focus:ring-blue-600 focus:ring-opacity-25'
        )
      }
      const size = {
        normal: 'h-10 text-base',
        small: 'h-6 text-sm'
      }

      return classNames([
        'w-full border transition focus:outline-none',
        theme[this.theme],
        size[this.size]
      ])
    }

  },

  methods: {

    onInput () {
      if (!this.touched) this.touched = true
      const el = this.$refs.input
      const value = el.value
      this.isValid = el.checkValidity()
      this.$emit('input', value)
    },

    focus () {
      this.$refs.input.focus()
    }

  }

}
</script>
