<template lang="pug">
  .flex-auto.flex.flex-col.w-full.select-none
    header.flex.relative
      PanelTabBar(
        :panel='panel'
        ref='tabBar'
        @focusContent='focusContent'
      )
      TabBarMenu(
        v-show='!isDragging'
        :panel='panel'
      )

    main.flex-grow.min-h-0.select-text(role='tabpanel')
      //- TODO: test keep-alive to preserve undo/redo history
      //- keep-alive

      //- Editor
      //- make use of "key"-attribute:
      //-   - tell Vue, that an editor is bound to a unique id
      //-   - force rerender, when key changes
      //- https://vuejs.org/v2/api/#key
      //- https://vuejs.org/v2/guide/conditional.html#Controlling-Reusable-Elements-with-key
      //- https://michaelnthiessen.com/force-re-render/
      PanelEditor(
        v-if='currentEditor'
        :key='currentEditor.id'
        ref='editorComponent'
        :editor-is-active='currentEditorIsActive'
        :editor-data='currentEditor'
        :symbol-data='currentSymbol'
        @close='$store.dispatch("closeTab", activeTabId)'
      )

      //- load content by component name
      component(v-else-if='activeTab && activeTab.componentName !== null' :is='activeTab.componentName')

      //- fallback message when no tabs found
      .flex(
        v-if='!tabIds.length'
      )
        .min-w-min.min-h-full
          Arrow(
            :p1='{ x: 49, y: 37}'
            :p2='{ x: 23, y: 10}'
            :width='55'
            :height='50'
            :options='{ flip: true, stretch: 0.53 }'
          )
        .relative.top-7.text-gray-800.text-sm.whitespace-nowrap
          | Drag a tab here, click "+" or&nbsp;
          span(
            @click='removePanel'
            class='cursor-pointer underline hover:no-underline'
          ) close this panel
          | .

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PanelEditor from '@/components/editor/PanelEditor.vue'
import PanelTabBar from '@/components/tabbar/PanelTabBar.vue'
import TabBarMenu from '@/components/tabbar/TabBarMenu.vue'
import Arrow from '@/components/Arrow'

export default {
  name: 'Panel',
  components: {
    PanelTabBar, TabBarMenu, PanelEditor, Arrow
  },

  props: {

    panelIsActive: {
      type: Boolean,
      required: true
    },

    panel: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      isDragging: state => state.workbench.isDragging
    }),
    ...mapGetters(['itemById', 'resolvePanelGroup']),

    tabIds () {
      return this.panel.tabIds
    },

    activeTabId () {
      return this.panel.activeTabId
    },

    activeTab () {
      if (!this.activeTabId) return null
      return this.itemById('tabs', this.activeTabId) || null
    },

    currentEditor () {
      if (!this.activeTab) return null
      return this.itemById('editors', this.activeTab.editorId) || null
    },

    currentSymbol () {
      if (!this.currentEditor) return null
      return this.itemById('symbols', this.currentEditor.symbolId) || null
    },

    currentEditorIsActive () {
      return !this.isDragging && this.panelIsActive
    }
  },

  methods: {

    removePanel () {
      const panelGroupId = this.resolvePanelGroup(this.panel.id).id
      this.$store.dispatch('removePanel', this.panel.id)

      if (this.itemById('panelGroups', panelGroupId).panelIds.length === 0) {
        this.$store.dispatch('removePanelGroup', panelGroupId)
      }
    },

    focusContent () {
      if (this.currentEditor) {
        setTimeout(() => {
          this.$refs.editorComponent.focusEditor()
        }, 0)
      }
    }
  }
}
</script>
