<template lang="pug">
  Modal(
    :close-button='false'
    @close='closeModal'
  )
    template(slot='header') Generate & Download
    div(slot='body')
      form(@submit.prevent='submit')
        p.mb-3 Enter a number of minimum characters you would like to generate:
        TextInput(
          ref='numberInput'
          type='number'
          v-model='charCount'
          min='50'
          :required='true'
          placeholder='Enter a number'
          :requirements='hint'
          @input='updateValue'
        )
    template(
      slot='footer'
    )
      Button(
        class='mr-2.5'
        label='Cancel'
        @click='closeModal'
      )
      Button(
        theme='primary'
        label='Generate & Download'
        :disabled='hasError || !charCount.length'
        @click='submit'
      )
</template>

<script>

export default {
  name: 'ModalDownload',

  data () {
    return {
      charCount: '1800',
      hasError: false,
      hint: 'A number is required (50 or greater)'
    }
  },

  mounted () {
    this.$refs.numberInput.focus()
  },

  methods: {

    updateValue (val) {
      this.hasError = !this.$refs.numberInput.isValid
    },

    submit () {
      if (this.hasError) return

      const charCount = parseInt(this.charCount)

      if (Number.isNaN(charCount)) {
        alert('Invalid input')
        return
      }

      this.$generateAndDownload({ charCount })
      this.closeModal()
    },

    closeModal () {
      this.$modal.close()
    }
  }
}
</script>
